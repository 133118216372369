import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import FullWidthSection from "../components/FullWidthSection"
import {
  colors,
  weights,
  smSectionHead,
  container,
  mediaQueries,
  pLight,
} from "../styles"

const Access = ({ data }) => {
  const leadersCss = css`
    padding-top: 20px;

    ${mediaQueries.phoneLarge} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-left: 40px;
      padding-right: 40px;
    }

    h2 {
      font-size: 21px;
      font-weight: ${weights.bold};
      text-align: center;
      margin-bottom: 6px;
      padding-top: 40px;

      ${mediaQueries.phoneLarge} {
        font-size: 27px;
      }
    }

    p {
      ${pLight};
      margin-bottom: 64px;
      ${mediaQueries.phoneLarge} {
        margin-bottom: 90px;
      }
    }

    .gatsby-image-wrapper > div {
      // Forcing correct image aspect ratio, overriding inline
      // gatsby-image provided styles
      ${mediaQueries.phoneLarge} {
        padding-bottom: 68% !important;
      }
    }
  `

  return (
    <Layout
      headerData={{
        title: "アクセス",
        mobileMinHeight: "93vh",
        height: "400px",
      }}
    >
      <FullWidthSection
        css={css`
          padding: 44px 0 0;

          ${mediaQueries.desktop} {
            padding: 120px 0;
          }
        `}
      >
        <h3 css={smSectionHead}>アクセスマップ</h3>

        <div css={[leadersCss, container.medium]}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d1577.0721300385582!2d139.7734165597396!3d35.70612200529018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e2!4m3!3m2!1d35.7064987!2d139.7743978!4m5!1s0x60188ea073e185a9%3A0xbfb21695d9701332!2z5LuK5LqV44OT44Or!3m2!1d35.7058003!2d139.7732651!5e0!3m2!1sja!2sjp!4v1454209681344"
            width="1200"
            height="600"
            frameBorder="0"
            allowFullScreen
            title="GoogleMap"
          ></iframe>
        </div>
      </FullWidthSection>
      <FullWidthSection
        backgroundColor={colors.yellow}
        textAlign="center"
        height="750px"
        minHeight="730px"
      >
        <h3 css={smSectionHead}>会社概要</h3>
        <div css={[leadersCss, container.medium]}>
          <table>
            <tbody>
              <tr>
                <td>所在地</td>
                <td>
                  〒110-0005
                  <br />
                  東京都台東区上野3-18-13今井ビル2F
                </td>
              </tr>
              <tr>
                <td>最寄り駅</td>
                <td>JR御徒町駅南口下車、徒歩2分</td>
              </tr>
              <tr>
                <td>勤務時間</td>
                <td>9:30～18:30</td>
              </tr>
              <tr>
                <td>TEL</td>
                <td>
                  <a href="tel:0338335681">03-3833-5681</a>
                  （代表）（受付時間：9:30～18:30 土日祝祭日を除く）
                </td>
              </tr>
              <tr>
                <td>FAX</td>
                <td>
                  <a href="fax:0338335682">03-3833-5682</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </FullWidthSection>
    </Layout>
  )
}

Access.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Access

export const query = graphql`
  {
    allFile(
      filter: {
        absolutePath: {
          regex: "/boston|oakland|team|severo|emond|davis|strom|slemp|andrade|may|topp|prendergast/"
        }
      }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 980, maxHeight: 480) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        mobileImage: childImageSharp {
          fluid(cropFocus: NORTH, maxHeight: 335, maxWidth: 335) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        desktopImage: childImageSharp {
          fluid(maxWidth: 530, srcSetBreakpoints: [480, 900, 1200]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        leaderDesktop: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
